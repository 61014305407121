<template>
  <div>
    <div
      id="alertSend"
      style="position: fixed; z-index: 3; width: 300px; margin-left: 82%"
    >
      <div
        v-for="(aler, index) in successAlert"
        :key="index"
        style="display: flex; flex-wrap: wrap"
      >
        <div
          class="alert alert-primary"
          role="alert"
          style="border: bold 5px; border-color: blue"
        >
          Сохранено в базе! <br /><b> {{ aler.message }}</b>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-outline-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="width: 250px; margin: 10px"
    >
      Фильтр по категории
    </button>

    <ul
      class="dropdown-menu"
      data-toggle="popover"
      style="background-color: #f2f2f2"
      id="ulOrder"
    >
      <li>
        <div
          class="btn-group dropend"
          v-for="(cat, index) in Category"
          :key="index"
          id="divOrder"
        >
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            :id="cat.Category"
            v-model="cat.checked"
            style="
              width: 30px;
              height: 30px;
              margin-left: -20px;
              margin-right: 10px;
              font-size: 11px;
            "
          />
          <label
            class="form-check-label"
            :for="cat.Category"
            style="margin-top: 3px"
          >
            {{ cat.Category }}
          </label>
        </div>
      </li>
      <li>
        <div class="btn-group dropend" id="divOrder">
          <input
            class="form-check-input"
            type="checkbox"
            value="all"
            v-model="selectOther.all"
            style="
              width: 30px;
              height: 30px;
              margin-left: -20px;
              margin-right: 10px;
              font-size: 11px;
            "
          />
          <label class="form-check-label" style="margin-top: 3px"> Все </label>
        </div>
      </li>
      <li>
        <div class="btn-group dropend" id="divOrder">
          <input
            class="form-check-input"
            type="checkbox"
            value="all"
            v-model="selectOther.noCat"
            @select="selectOtherCat"
            style="
              width: 30px;
              height: 30px;
              margin-left: -20px;
              margin-right: 10px;
              font-size: 11px;
            "
          />
          <label class="form-check-label" style="margin-top: 3px">
            Без категории
          </label>
        </div>
      </li>
    </ul>

    <button
      type="button"
      class="btn btn-outline-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="width: 200px; margin: 10px"
    >
      Уровень образования
    </button>
    <ul
      class="dropdown-menu"
      data-toggle="popover"
      style="background-color: #f2f2f2"
    >
      <li>
        <div
          class="btn-group dropend"
          v-for="(cat, index) in levelClass"
          :key="index"
          id="divOrder"
        >
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            :id="cat.Level"
            v-model="cat.checked"
            style="
              width: 30px;
              height: 30px;
              margin-left: -20px;
              margin-right: 10px;
              font-size: 11px;
            "
          />
          <label
            class="form-check-label"
            :for="cat.Category"
            style="margin-top: 3px"
          >
            {{ cat.Level }}
          </label>
        </div>
      </li>
    </ul>

    <button
      v-if="filteredList.length > 0 && activeChange"
      class="btn btn-outline-secondary"
      @click="showActions()"
      style="margin: 10px"
    >
      Скрыть действия
    </button>

    <button
      v-if="filteredList.length > 0 && !activeChange"
      class="btn btn-outline-secondary"
      @click="showActions()"
      style="margin: 10px"
    >
      Показать действия
    </button>

    <button
      style="margin: 10px"
      class="btn btn-outline-success"
      @click="pickTable()"
    >
      Скопировать таблицу для вставки в EXCEL
    </button>

    <div style="display: flex; flex-wrap: nowrap; width: 400px">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        name="onlyComp"
        v-model="onlyCompensation"
        style="margin: 10px; padding: 12px"
      />
      <label for="onlyComp" style="padding-top: 10px"
        >Только дети с компенсацией питания</label
      >
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        name="checkDateOrder"
        v-model="checkDateOrder"
        style="margin: 10px; padding: 12px"
      />
      <label for="checkDateOrder" style="padding-top: 10px"
        >Проверка справок (Для МО и МДиМО)</label
      >
    </div>
    <table
      v-if="loading"
      id="table-data-summary"
      class="reciept-table"
      style="margin-bottom: 30px"
    >
      <tr>
        <th rowspan="2">№</th>
        <th rowspan="2">Класс</th>
        <th rowspan="2">Фамилия</th>
        <th rowspan="2">Имя</th>
        <th rowspan="2">Категория</th>
        <th rowspan="2">Пакет документов</th>
        <th colspan="2">Приказ</th>
        <th colspan="2">Справка</th>
        <th rowspan="2" style="max-width: 100px">
          Категория, на которую сданы документы
        </th>
      </tr>
      <tr>
        <th id="fixTop">№</th>
        <th id="fixTop">Дата</th>
        <th id="fixTop">№</th>
        <th id="fixTop">Дата</th>
      </tr>
      <tr v-for="(student, index) in printFilteredList" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ student.classID }}</td>
        <td>{{ student.FirstName }}</td>
        <td>{{ student.LastName }}</td>
        <td>
          {{ student.Category }}

          <div
            v-if="student.Category === '' || !student.Order.documents"
            class="actions"
            style="display: flex; flex-wrap: nowrap; width: 150px"
          >
            <select
              class="form-select form-select-sm"
              name="marks"
              v-bind:title="student.Category"
              v-bind:id="student._id"
              style="height: 30px"
            >
              <option value=""></option>
              <option v-for="caus in Category" :key="caus.Category">
                {{ caus.Category }}
              </option>
            </select>

            <button
              class="btn btn-success btn-sm"
              @click="updateGlobalCat(student._id)"
            >
              Обновить
            </button>
          </div>
        </td>
        <!-- Пакет документов -->
        <td>
          <div v-if="student.progress != 100">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <div
            style="display: flex; align-items: center"
            v-if="student.Category != ''"
          >
            <div class="actions">
              <input
                @change="updateCatOrderStudent(student)"
                class="form-check-input"
                type="checkbox"
                value=""
                v-model="student.Order.documents"
                id="flexCheckChecked"
                style="margin: 3px; padding: 9px"
              />
            </div>

            <span v-if="student.Order.documents" style="color: green">Сдан</span
            ><span style="color: red" v-else>Не сдан</span>
          </div>
        </td>
        <!-- № приказа -->
        <td
          style="width: 100px"
          @dblclick="
            if (activeChange) {
              showInput.changeNumberOrderText = student._id;
            }
          "
        >
          <div v-if="student.progress != 100">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <div
            v-if="
              showInput.changeNumberOrderText == student._id &&
              student.Category != ''
            "
          >
            <input
              v-focus
              type="text"
              class="form-control"
              @blur="showInput.changeNumberOrderText = ''"
              @change="updateCatOrderStudent(student)"
              required
              v-model="student.Order.Order.text"
              style="width: 100px"
            />
          </div>
          <div
            v-if="
              showInput.changeNumberOrderText != student._id &&
              student.Category != ''
            "
          >
            <a
              id="aChange"
              v-if="student.Order.Order.text == ''"
              @click="showInput.changeNumberOrderText = student._id"
              class="actions"
            >
              Заполнить
            </a>
            <a v-else>{{ student.Order.Order.text }}</a>
          </div>
        </td>
        <!-- Дата приказа -->
        <td
          style="width: 150px"
          @dblclick="
            if (activeChange) {
              showInput.changeNumberOrderDate = student._id;
            }
          "
        >
          <div v-if="student.progress != 100">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <div
            v-if="
              showInput.changeNumberOrderDate == student._id &&
              student.Category != ''
            "
          >
            <input
              v-focus
              type="date"
              class="form-control"
              @blur="updateCatOrderStudent(student)"
              @keypress.enter="updateCatOrderStudent(student)"
              required
              v-model="student.Order.Order.date"
              style="width: 150px"
            />
          </div>
          <div
            v-if="
              showInput.changeNumberOrderDate != student._id &&
              student.Category != ''
            "
          >
            <a
              id="aChange"
              v-if="student.Order.Order.date == ''"
              @click="showInput.changeNumberOrderDate = student._id"
              class="actions"
            >
              Заполнить
            </a>
            <a v-else>{{
              new Date(student.Order.Order.date).toLocaleDateString()
            }}</a>
          </div>
        </td>

        <!-- Номер справки -->
        <td
          style="width: 100px"
          @dblclick="
            if (activeChange) {
              showInput.changeNumberReferenceText = student._id;
            }
          "
        >
          <div v-if="student.progress != 100">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <div
            v-if="
              showInput.changeNumberReferenceText == student._id &&
              student.Category != ''
            "
          >
            <input
              v-focus
              type="text"
              class="form-control"
              @blur="showInput.changeNumberReferenceText = ''"
              @change="updateCatOrderStudent(student)"
              required
              v-model="student.Order.reference.text"
              style="width: 100px"
            />
          </div>
          <div
            v-if="
              showInput.changeNumberReferenceText != student._id &&
              student.Category != ''
            "
          >
            <a
              id="aChange"
              v-if="student.Order.reference.text == ''"
              @click="showInput.changeNumberReferenceText = student._id"
              class="actions"
            >
              Заполнить
            </a>
            <a v-else>{{ student.Order.reference.text }}</a>
          </div>
        </td>
        <!-- дата справки -->
        <td
          style="width: 150px"
          @dblclick="
            if (activeChange) {
              showInput.changeNumberReferenceDate = student._id;
            }
          "
        >
          <div v-if="student.progress != 100">
            <v-progress-linear
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          <div
            v-if="
              showInput.changeNumberReferenceDate == student._id &&
              student.Category != ''
            "
          >
            <input
              v-focus
              type="date"
              class="form-control"
              @blur="updateCatOrderStudent(student)"
              @keypress.enter="updateCatOrderStudent(student)"
              required
              v-model="student.Order.reference.date"
              style="width: 150px"
            />
          </div>
          <div
            v-if="
              showInput.changeNumberReferenceDate != student._id &&
              student.Category != ''
            "
          >
            <a
              id="aChange"
              v-if="student.Order.reference.date == ''"
              @click="showInput.changeNumberReferenceDate = student._id"
              class="actions"
            >
              Заполнить
            </a>
            <a v-else>{{
              new Date(student.Order.reference.date).toLocaleDateString()
            }}</a>
            <div
              v-if="
                validityOrder(student.Order.reference.date) < 14 &&
                (student.Category === 'МО' || student.Category === 'МД и МО') &&
                checkDateOrder
              "
              style="color: white; background-color: crimson"
            >
              Осталось:
              {{ validityOrder(student.Order.reference.date) }} дней
            </div>
          </div>
        </td>

        <td>
          <div v-if="student.progress != 100">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>

          <div
            style="
              display: flex;
              align-items: baseline;
              flex-wrap: wrap;
              width: 300px;
            "
          >
            <div style="display: flex; align-items: baseline">
              <a @dblclick="clearCat(student)">{{ student.Order.cat }}</a>

              <span class="actions">
                <div v-if="student.Order.cat != student.Category">
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="updateCat(student)"
                  >
                    <span style="color: white; font-size: 12px"
                      >Не совпадает! <br />
                      Привязать текущую категорию</span
                    >
                  </button>
                </div>
              </span>
              <div
                v-if="
                  student.Order.cat == 'Платник' || student.Order.cat == 'МД'
                "
                class="actions"
              >
                <input
                  @change="updateCatOrderStudent(student)"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="student.Order.compensation"
                  id="flexCheckChecked"
                  style="margin: 3px; padding: 9px"
                />
              </div>
              <label
                id="flexCheckChecked"
                for=""
                v-if="student.Order.compensation"
              >
                &nbsp;Компенсация</label
              >
            </div>
            <div v-if="activeChange && student.Order.compensation">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 50 50"
                xml:space="preserve"
                width="30px"
                @click="updateBankDetails(student)"
                style="cursor: pointer"
              >
                <circle style="fill: #43b05c" cx="25" cy="25" r="25" />
                <line
                  style="
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  "
                  x1="25"
                  y1="13"
                  x2="25"
                  y2="38"
                />
                <line
                  style="
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-miterlimit: 10;
                  "
                  x1="37.5"
                  y1="25"
                  x2="12.5"
                  y2="25"
                />
              </svg>
              Банковские реквизиты
            </div>
          </div>
          <!-- <button @click="updateCatOrderStudent(student)">Отправить</button> -->
        </td>
      </tr>
    </table>

    <v-dialog v-model="dialogCompensation" width="590px">
      <v-card>
        <v-card-text style="color: black; font-size: 20px">
          Обновление данных для <br />
          {{ dialogPrint.LastName }} {{ dialogPrint.FirstName }}({{
            dialogPrint.Category
          }})
        </v-card-text>
        <v-card-actions style="display: flex; flex-wrap: wrap">
          <div v-if="dialogPrint.Order" style="width: 500px">
            <div>Лицевой счет:</div>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="1"
              type="text"
              v-model="dialogPrint.Order.ls"
            />
            <div>Реквизиты:</div>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              style="height: 85px"
              type="text"
              v-model="dialogPrint.Order.bank"
            />
            <input
              v-model="dialogPrint.Order.Sberbank"
              name="checkboxSberbank"
              class="form-check-input"
              type="checkbox"
              style="padding: 15px"
            />
            <label
              for="checkboxSberbank"
              style="padding-left: 10px; padding-top: 10px"
              >Банк: Сбербанк (выгрузка CSV)</label
            >
            <div>Фамилия получателя:</div>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="1"
              type="text"
              v-model="dialogPrint.Order.recipientsLastName"
            />
            <div>Имя получателя:</div>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="1"
              type="text"
              v-model="dialogPrint.Order.recipientsFirstName"
            />
            <div>Отчество получателя:</div>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="1"
              type="text"
              v-model="dialogPrint.Order.recipientsSurname"
            />
          </div>

          <br />
          <v-btn
            style="margin-top: 10px; margin-bottom: 10px"
            color="success"
            block
            @click="updateBankDetailsServer(dialogPrint)"
            >Обновить</v-btn
          >
          <br />
          <v-btn color="primary" block @click="dialogCompensation = false"
            >Отмена</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
export default {
  directives: {
    focus: {
      // определение директивы
      inserted: function (el) {
        el.focus();
      },
    },
  },
  data() {
    return {
      dialogPrint: "",
      dialogCompensation: false,
      onlyCompensation: false,
      checkDateOrder: true,
      successAlert: [],
      classList: [],
      studentsList: [],
      loading: false,
      levelClass: [
        { Level: "Начальная школа", checked: true, levelNumber: 1 },
        { Level: "Средняя школа", checked: true, levelNumber: 2 },
        { Level: "Старшая школа", checked: true, levelNumber: 3 },
      ],
      activeChange: true,
      Category: [],
      selectOther: { all: false, noCat: false },
      showInput: {
        changeNumberOrderText: "",
        changeNumberOrderDate: "",
        changeNumberReferenceText: "",
        changeNumberReferenceDate: "",
      },
    };
  },

  methods: {
    init() {
      this.loading = false;
      this.getStudents();
      this.getCategory();
    },
    changeCompensation(student) {
      console.log(student);
    },

    async updateGlobalCat(ID) {
      var data = new Array();
      data[0] = document.getElementById(ID).value;

      await TutorialDataService.updateCat(ID, data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          this.init();
        })
        .catch((e) => {
          console.log("1111111111", e);
          alert("Ошибка при обновлении");
        });
    },
    pickTable() {
      let target = document.getElementById("table-data-summary");
      let rng, sel;
      if (document.createRange) {
        rng = document.createRange();
        rng.selectNode(target);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(rng);
      } else {
        rng = document.body.createTextRange();
        rng.moveToElementText(target);
        rng.select();
      }

      document.execCommand("copy");
    },

    validityOrder(elem) {
      let date1 = new Date(elem);

      // Получение нужных значений
      let year = date1.getFullYear();
      let month = date1.getMonth();
      let day = date1.getDate();
      // Создание новой даты
      date1 = new Date(year, month + 6, day);
      let date2 = new Date();

      let timeDiff = date1.getTime() - date2.getTime();
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      return diffDays;
    },
    updateBankDetails(student) {
      this.dialogCompensation = true;
      this.dialogPrint = student;
      // if (!student.Order.bank) {
      //   student.Order.bank = "";
      // }
      // let text =
      //   "Вы хотите обновить для " +
      //   student.FirstName +
      //   " " +
      //   student.LastName +
      //   "? Сейчас: " +
      //   student.Order.bank;
      // let bank = prompt(text);
      // console.log(bank);
      // if (bank != null) {
      //   student.Order.bank = bank;
      //   this.updateCatOrderStudent(student);
      // }
    },
    async updateBankDetailsServer(student) {
      await this.updateCatOrderStudent(student);
      this.dialogCompensation = false;
    },

    selectOtherCat() {
      console.log(this.Category);
    },
    showActions() {
      this.activeChange = !this.activeChange;
      let el = document.querySelectorAll(".actions");

      if (this.activeChange) {
        for (let i = 0; i < el.length; i++) {
          el[i].hidden = false;
        }
      } else {
        for (let i = 0; i < el.length; i++) {
          el[i].hidden = true;
        }
      }
    },
    updateCat(student) {
      student.Order.cat = student.Category;
      this.updateCatOrderStudent(student);
    },
    clearCat(student) {
      if (
        confirm(
          "Вы точно хотите очистить прикрепленную категорию? ВНИМАНИЕ! Это не уберет категорию у ребенка. В системе будет сохраняться отметки ВЫСТАВЛЕННОЙ категории (4 столбик), Прикрепленная категория нужна только для того, чтобы указать, какие именно документы принесли",
        )
      ) {
        student.Order.cat = "";
        this.updateCatOrderStudent(student);
      }
    },
    async updateCatOrderStudent(ID) {
      var data = new Array();
      data[0] = ID.Order;
      if (!ID.Order.compensation) {
        ID.Order.compensation = false;
      }
      this.showInput = {
        changeNumberOrderText: "",
        changeNumberOrderDate: "",
        changeNumberReferenceText: "",
        changeNumberReferenceDate: "",
      };

      let id = ID._id;
      ID.progress = 10;

      await TutorialDataService.updateCatOrderStudent(id, data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);

          if (response.data.message === "Tutorial was updated successfully.") {
            ID.progress = 100;
            this.successAlert.push({
              message: ID.classID + " " + ID.FirstName + " " + ID.LastName,
              show: false,
            });

            setTimeout(() => {
              this.successAlert.shift();
            }, 3000);
          } else {
            alert(
              "При сохранении что-то пошло не так! Попробуйте позже, обязательно перепроверьте данные. ",
            );
          }
        })
        .catch((e) => {
          console.log("1111111111", e);
          alert(
            "При сохранении что-то пошло не так! Попробуйте позже, обязательно перепроверьте данные. ",
          );
        });
    },

    getStudents() {
      TutorialDataService.findStudentByClassID()
        .then((response) => {
          this.studentsList.splice(response.data);
          let a = new Array();
          a = Object.values(response.data);

          for (let i = 0; i < a.length; i++) {
            a[i].progress = 100;
            if (a[i].Order == null || a[i].Order == undefined) {
              a[i].Order = new Object({
                documents: false,
                Order: { text: "", date: "" },
                reference: { text: "", date: "" },
                cat: "",
              });
            }
            this.$set(this.studentsList, i, a[i]);
          }
          this.getClass();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCategory() {
      TutorialDataService.getCategory()
        .then((response) => {
          this.Category = response.data.map(this.getDispleyCategory);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getClass() {
      await TutorialDataService.getAllCLass()
        .then((response) => {
          this.classList = response.data.map(this.mapClass);
          for (let i = 0; i < this.classList.length; i++) {
            this.getClassName(this.classList[i]);
          }

          this.loading = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getClassName(data) {
      for (let j = 0; j < this.studentsList.length; j++) {
        if (this.studentsList[j].classID === data.classID) {
          this.studentsList[j].classID = data.className;
          this.studentsList[j].level = data.level;
        }
      }
    },
    mapClass(data) {
      return {
        classID: data._id,
        className: data.className,
        level: data.level,
      };
    },
    getDispleyCategory(data) {
      return {
        Category: data.cat,
        id: data._id,
      };
    },
  },

  mounted() {
    this.init();
  },
  computed: {
    filteredList: function () {
      let Cat = this.Category;
      // return this.studentsList
      if (this.selectOther.all == true)
        return this.studentsList
          .filter(function (elem) {
            return elem;
          })
          .sort((a, b) => (a.classID > b.classID ? 1 : -1));
      if (this.selectOther.noCat == true)
        return this.studentsList
          .filter(function (elem) {
            if (elem.Category == "") return elem;
          })
          .sort((a, b) => (a.classID > b.classID ? 1 : -1));
      return this.studentsList
        .filter(function (elem) {
          //  return elem;
          for (let i = 0; i < Cat.length; i++) {
            if (Cat[i].checked) {
              if (Cat[i].Category === elem.Category) {
                return elem;
              }
            }
          }
        })
        .sort((a, b) => (a.classID > b.classID ? 1 : -1));
    },
    printFilteredList: function () {
      let levelClassF = this.levelClass;
      let onlyCompensationF = this.onlyCompensation;
      return this.filteredList
        .filter(function (elem) {
          for (let i = 0; i < levelClassF.length; i++) {
            if (
              elem.level === levelClassF[i].levelNumber &&
              levelClassF[i].checked
            ) {
              if (onlyCompensationF) {
                if (elem.Order.compensation) {
                  return elem;
                }
              } else {
                return elem;
              }
            }
          }
        })
        .sort(function (vote1, vote2) {
          if (vote1.classID > vote2.classID) return 1;
          if (vote1.classID < vote2.classID) return -1;

          if (vote1.FirstName > vote2.FirstName) return 1;
          if (vote1.FirstName < vote2.FirstName) return -1;
        });
    },
  },
};
</script>

<style scoped>
#aChange {
  color: red;
  font-size: 14px;
  line-height: 0.5px;
}
TH {
  position: sticky;
  top: 0;
  z-index: 2;
}
#fixTop {
  top: 29px;
}
tr:nth-child(odd) {
  background-color: #ffffff;
}

tr:nth-child(even) {
  background-color: #eeeeee;
}
#ulOrder {
  font-size: auto;
  margin: auto;
}
#ulOrder > li {
  font-size: auto;
}
#divOrder {
  display: flex;
  align-items: column;
  width: auto;
  margin: 5px;
  background-color: #fcfcfc;
}
label {
  padding: 0px;
  width: auto;
}
.form-control {
  height: 27px;
}
</style>
